import React, { useEffect, useState } from "react";

import { useContext } from "react";
import {
  ReactiveContext,
  CurrentScreenWidth,
} from ".././Store/PageAnimation-Context";

import styled from "styled-components";
import { motion } from "framer-motion";

import ProfileBox from "../SectionOne/ProfileBox";
import Button from "../SharedUI/Button";
import { sDevice, device } from "../Util/DeviceBP";



const Cover = styled(motion.div)`

@media screen and  (${device.mobileS}) {
   /* border: 1px solid red; */
   height: 100%;
    
  }
  @media ${device.laptopL && device.laptop} {
    display: block;
    float: left;
    width: 90%;
    max-width: 90%;
    height: 100%;
    max-height: 80vh;
    min-height: inherit;
    text-align: center;
    padding: 70px 30px 45px;
    overflow: hidden;
    border-radius: 32px;
  }


`;

const CopyRights = styled.div`
  display: block;
  color: #bbb;
  width: 100%;
  position: relative;
  bottom: 0;
  left: 0;
  padding: 5px 10px 15px;
  line-height: 14px;
  font-size: 12px;
  text-align: center;
`;

const ActionButtons = styled(motion.div)`
  display: block;
  margin-top: 50px;

  @media screen and  (${device.mobileS}) {
   display:none;
   justify-content: center;
    
  }

  @media screen and  (${device.tablet}){
    display: block;
  }
`;

export default function CoverPage({ profile, handleInteractive }) {

  const pdfResume = "resume.pdf"
  //Get data from the context
  const {interactive, deviceWidth,} = useContext(ReactiveContext);
  
  const onDownloadClick = () =>{
    const resumeLink =  document.createElement("a")
    resumeLink.href = pdfResume
    resumeLink.download = "Cristobal_Fallas_Mora.pdf"
    document.body.appendChild(resumeLink);
    resumeLink.click()
    document.body.removeChild(resumeLink)
  }

  //Define animations based on Size
  const animateLaptop = {
    maxWidth: "380px",
    float: "left",
    backgroundColor: "#1b1b1bbb",
    borderBottomRightRadius: "0px",
    borderTopRightRadius: "0px",
  };

  return (
    <>
      <Cover animate={interactive && deviceWidth > 768 ? animateLaptop : null}>
        <ProfileBox
          viewInteractive={interactive}
          profile={profile}
        ></ProfileBox>
        <ActionButtons
          animate={interactive ? { marginLeft: "20px" } : null}
        >
          <Button
            clickHandler={onDownloadClick}
            id={"download"}
            label="Download PDF"
          ></Button>
          <Button
            animate={
              interactive ? { display: "none", marginLeft: "20px" } : null
            }
            id={"interactive"}
            label="View Interactively"
            clickHandler={handleInteractive}
          ></Button>
        </ActionButtons>
      </Cover>
      {/* <CopyRights>
        <p> © 2024 All rights reserved </p>
      </CopyRights> */}
    </>
  );
}
