import React, { useContext, useState } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { ReactiveContext } from ".././Store/PageAnimation-Context";
import Sfab from "../SharedUI/Sfab";
import { device, screenMaxes } from "../Util/DeviceBP";
import Button from "../SharedUI/Button";

const Profile = styled(motion.div)`
  margin: auto;
  height: auto;
  padding: 10px;
  width: fit-content;
  color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  min-width: 20vw;

  & img {
    margin: auto;
    width: 75px;
    height: 75px;
    border-radius: 50%;
  }
  & h2 {
    margin: auto;
    font-size: 24px;
    margin: 2px;
  }

  & h3 {
    font-size: 18px;
    color: #00cefb;
    font-style: normal;
    margin: auto;
  }

  @media screen and (${device.allMobiles}) {
    border-top-right-radius: 32px;
    border-top-left-radius: 32px;
    border-bottom: 3px solid #00cdfb71;
    background-color: #1d1d1d;
    width: 100%;
    flex-direction: row;
    justify-content: left;
    & img {
      margin: 0;
      margin-right: 10px;
      width: 50px;
      height: 50px;
    }
    & h2 {
      font-size: 16px;
      margin: 2px;
    }

    & h3 {
      font-size: 14px;
      color: #9d9d9d;
      font-style: normal;
      margin: 1px;
    }

    & p {
      font-size: 14px;
      color: #00cefb;
      font-style: normal;
      margin: auto;
      text-align: left;
      cursor: pointer;
    }
  }

  @media screen and (${device.allTablets}), (${device.allLaptops}) {
    border-radius: 32px;
    background-color: #1d1d1d;
    position: relative;
    float: left;
    margin: 0;
    margin-top: 0;
    height: 100%;
    padding: 10px;
    width: 100%;
    color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;

    & img {
      /* position: absolute; */
      margin: 0 auto 10px;
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
    & h2 {
      margin: auto;
      font-size: 20px;
      margin: 2px;
      text-align: center;
    }

    & h3 {
      font-size: 16px;
      color: #00cefb;
      font-style: normal;
      margin: auto;
      text-align: center;
    }
  }
`;

const MobileMenu = styled(motion.div)`
  display: none;
  padding: 5px 1px;
  margin-top: 10px;
  border: 1px solid #00cefb7b;
  border-radius: 32px;

  width: 100%;
`;

const LaptopMenu = styled(motion.div)`
  display: flex;
  margin-top: 5vh;
  justify-content: center;
`;

export default function ProfileBox({ profile, onClickHandler }) {
  const pdfResume = "resume.pdf";
  const [showMenu, setShowMenu] = useState(false);

  function handleClick() {
    setShowMenu(!showMenu);
  }

  const { deviceWidth, interactive, handleInteractive } =
    useContext(ReactiveContext);

  const onDownloadClick = () => {
    const resumeLink = document.createElement("a");
    resumeLink.href = pdfResume;
    resumeLink.download = "Cristobal_Fallas_Mora.pdf";
    document.body.appendChild(resumeLink);
    resumeLink.click();
    document.body.removeChild(resumeLink);
  };

  return (
    <Profile
      animate={
        interactive &&
        deviceWidth > 767 && {
          width: "30%",
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }
      }
    >
      <img src={profile.img} alt={profile.name} />
      <div>
        {" "}
        <h2>{profile.name}</h2>
        <h3>{profile.title}</h3>
        {deviceWidth < 767 && <p onClick={onDownloadClick}>Download a PDF</p>}
      </div>
      {deviceWidth < 767 && (
        <Sfab
          icon={"menu"}
          size={"small"}
          onClick={handleClick}
          color={"primary"}
        ></Sfab>
      )}
      <AnimatePresence>
        {showMenu && deviceWidth < 767 && (
          <MobileMenu
            initial={{ opacity: 0, y: -20 }}
            animate={{ display: "flex", opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
          >
            <Sfab
              size={"small"}
              icon={"A"}
              color={"secondary"}
              id={"about"}
              onClick={onClickHandler}
            ></Sfab>
            <Sfab
              size={"small"}
              icon={"E"}
              color={"secondary"}
              id={"experience"}
              onClick={onClickHandler}
            ></Sfab>
            <Sfab
              size={"small"}
              icon={"K"}
              color={"secondary"}
              id={"knowledge"}
              onClick={onClickHandler}
            ></Sfab>
            <Sfab
              size={"small"}
              icon={"L"}
              color={"secondary"}
              id={"languages"}
              onClick={onClickHandler}
            ></Sfab>
          </MobileMenu>
        )}
        {deviceWidth > 767 && (
          <LaptopMenu>
            {!interactive && (
              <Button
                label={"View interactively"}
                clickHandler={handleInteractive}
              />
            )}

            <Button label={"Download PDF"} clickHandler={onDownloadClick} />
          </LaptopMenu>
        )}
      </AnimatePresence>
    </Profile>
  );
}
