import styled from "styled-components";
import { device } from "../../Util/DeviceBP";
import { motion } from "framer-motion";

export const Container = styled(motion.div)`
  padding: 0 5px;
  margin: auto;
  border-radius: 15px;
  width: 100%;
 
  p {
    margin: auto;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 20px;
    /* width: 80%; */
    text-align: center;
    font-size: 15px;
  }

  h1 {
    font-size: 24px;
    color: #00cdfb;
  }

  h2 {
    font-size: 22px;
    color: #00cdfb;
  }

  @media screen and (${device.allMobiles}) {
    p{
      margin-bottom: 10px;
     
    }
  }
`;
