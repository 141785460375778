import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import { device } from "../Util/DeviceBP";

const Profile = styled(motion.div)`
  margin: auto;
  height: auto;
  padding: 10px;
  width: fit-content;
  color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;

  & img {
    /* position: absolute; */
    margin: auto;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 20px;
  }
  & h2 {
    margin: auto;
    font-size: 24px;
    margin: 2px;
  }

  & h3 {
    font-size: 18px;
    color: #00cefb;
    font-style: normal;
    margin: auto;
  }
  /* 
  @media (max-width: 850px) {
    width: fit-content;

    & img {
      width: 50px;
      height: 50px;
    }

    & h2 {
      font-size: 16px;
      margin: 2px;
    }

    & h3 {
      font-size: 14px;
      color: #9d9d9d;
      font-style: normal;
      margin: 1px;
    } */

  @media screen and (${device.allMobiles}) {
    border-top-right-radius: 32px;
    border-bottom: 3px solid #00cdfb71;
    background-color: #1d1d1d;
    width: 100%;
    flex-direction: row;
    justify-content: left;
    & img {
      margin: 0;
      margin-right: 10px;
      width: 50px;
      height: 50px;
    }
    & h2 {
      font-size: 16px;
      margin: 2px;
    }

    & h3 {
      font-size: 14px;
      color: #9d9d9d;
      font-style: normal;
      margin: 1px;
    }
  }

  @media screen and (${device.allLaptops}) , (${device.allTablets}){
    margin: auto;
    height: auto;
    padding: 10px;
    width: fit-content;
    color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;

    & img {
      /* position: absolute; */
      margin: auto;
      width: 150px;
      height: 150px;
      border-radius: 50%;
      margin-bottom: 20px;
    }
    & h2 {
      margin: auto;
      font-size: 24px;
      margin: 2px;
      text-align:center
    }

    & h3 {
      font-size: 18px;
      color: #00cefb;
      font-style: normal;
      margin: auto;
      text-align:center
    }
  }
`;

export default function ProfileBox({ profile, viewInteractive }) {
  return (
    <Profile>
      <img src={profile.img} alt={profile.name} />
      <div>
        {" "}
        <h2>{profile.name}</h2>
        <h3>{profile.title}</h3>
      </div>
    </Profile>
  );
}
