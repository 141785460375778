import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { Fragment, useState } from "react";
import { device } from "../Util/DeviceBP";
import { Container } from "./StyledComponents/Container";
const LanguagesContainer = styled(motion.div)`
  h1 {
    color: #00cdfb;
    display: grid;
    justify-content: left;
    margin-left: 50px;
    font-size: 24px;
  }

  p {
    font-size: 15px;
    color: #ffff;
    margin-top: 10px;
    margin-bottom: 15x;
    display: grid;
    margin-left: 50px;
    justify-content: left;
    text-align: left;
  }

  @media screen and (${device.allMobiles}) {
    p {
      margin-top: 5px;
      margin-bottom: 10px;
      margin-left: 10px;
      font-size: 15px;
    }
    h1 {
      margin-left: 10px;
      font-size: 22px;
    }
  }
`;

const UList = styled(motion.ul)`

  display: block;
  margin: auto;
  margin-top: 25px;

  @media screen and (${device.allMobiles}) {
    margin-top: 20px;
  }
`;
const ListItem = styled(motion.li)`
  width: fit-content;
  min-width: 65%;
  border: 1px solid;
  border-bottom-left-radius: 32px;
  border-top-left-radius: 32px;
  border-bottom: 1px solid #ffffff8c;
  border-left-color: transparent;
  border-top-color: transparent;
  padding-bottom: 5px;
  border-right: none;
  margin-left: 50px;
  margin-bottom: 37px;
  display: flex;
  gap: 1rem;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  cursor: pointer;

  h1 {
    margin-left: 0;
    color: #fff;
    display: grid;
    justify-content: left;
    z-index: -1;
    font-size: 24px;
  }
  p {
    margin: 0;
    color: #00cdfb;
    display: grid;
    justify-content: left;
    z-index: -1;
    font-size: 15px;
  }

  img {
    margin: 0;
    width: 55px;
    height: 55px;
    border-radius: 100%;
    object-fit: cover;
  }

  @media screen and (${device.allMobiles}) {
    margin-left: 5px;
    min-width: 95%;
    h1 {
      font-size: 18px;
    }
    img {
      width: 35px;
      height: 35px;
    }
  }
`;

const AnimateP = styled(motion.div)`
  width: 1005;
  display: block;

  background: rgb(0, 205, 251);
  background: linear-gradient(
    90deg,
    rgba(0, 205, 251, 0) 0%,
    rgba(0, 205, 251, 0.20772058823529416) 95%,
    rgba(0, 212, 255, 0.12088585434173671) 100%
  );
  color: white;
  margin: 0;
  margin-top: -35px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 20px 20px 20px 40px;
  text-align: right;
  align-self: end;
  border-bottom-right-radius: 32px;
  border-top-right-radius: 32px;

  @media screen and (${device.allMobiles}) {
    padding: 10px;
    font-size: 14px;
  }
`;
const LanguageInfo = styled(motion.div)``;

export default function Languages({ section }) {
  const languages = section.list;

  const [showDetail, setShowDetail] = useState(false);
  const [activeLan, setActiveLan] = useState("none");

  const handleHover = (event) => {
    setShowDetail(true);
    setActiveLan(event.target.id);
  };

  return (
    <AnimatePresence>
      <Container
        initial={{ x: -300, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 300, opacity: 0 }}
      >
        <h1>{section.title}</h1>
        <p>{section.description}</p>
        <UList>
          {languages.map((lan) => {
            return (
              <Fragment key={lan.title} >
                <ListItem
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  id={lan.title}
                  key={lan.title}
                  onMouseEnter={handleHover}
                  onMouseLeave={() => {
                    setShowDetail(false);
                  }}
                  onClick={handleHover}
                >
                  <motion.img
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    src={lan.img}
                    alt={lan.title}
                  />
                  <LanguageInfo>
                    <h1>{lan.title}</h1>
                    <p>Level: {lan.level}</p>
                  </LanguageInfo>
                </ListItem>
                <AnimatePresence>
                  <AnimateP
                    initial={{ display: "none", opacity: 0, x: -100 }}
                    animate={
                      showDetail && activeLan === lan.title
                        ? { display: "block", opacity: 1, x: 0 }
                        : null
                    }
                    exit={{ display: "none", opacity: 0 }}
                  >
                    {lan.description}
                  </AnimateP>
                </AnimatePresence>
              </Fragment>
            );
          })}
        </UList>
      </Container>
    </AnimatePresence>
  );
}
