import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { device } from "../Util/DeviceBP";

const StandardButton = styled(motion.button)`
  border-radius: 32px;
  background-color: ${({ active }) => (active ? "#00cdfb7a" : "transparent")};
  border: 1px solid;
  border-color: ${({ active }) => (active ? "#00cdfb" : "#fff")};
  padding: 8px;
  margin: 5px;
  text-decoration: none;
  list-style: none;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  transition: 0.5s;

  &:hover {
    background-color: #00cdfb28;
    color: #fff;
    border-color: #00cdfb;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
      rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  }

  @media screen and (${device.allMobiles}) {
    padding: 5px 5px ;
    margin: 5px;
    font-size: 12px;
  }
`;

export default function Button({ label, clickHandler, animate, id, active }) {
  return (
    <StandardButton
    
      active={active === id ? active : undefined}
      animate={animate}
      onClick={() => {
        clickHandler(id);
      }}
      id={id}
    >
      {label}
    </StandardButton>
  );
}
