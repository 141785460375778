import { interpolate } from "framer-motion";
import { createContext } from "react";

export const AnimationContext = createContext({ x: 0, y: 0 });

export const ReactiveContext = createContext({
  deviceWidth:0,
  interactive: false,
  handleInteractive: () => {},
});

export const CurrentScreenWidth = createContext({ width: 0 });
