import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import HelpOutlineSharpIcon from "@mui/icons-material/HelpOutlineSharp";
import InfoIcon from "@mui/icons-material/Info";
import WorkIcon from "@mui/icons-material/Work";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import PublicIcon from "@mui/icons-material/Public";
import MenuSharpIcon from "@mui/icons-material/MenuSharp";
import styles from "./Sfab.module.css";
import { motion } from "framer-motion";

const fabTheme = createTheme({
  palette: {
    primary: {
      main: "#00cdfb",
    },
    secondary: {
      main: "#3d3d3d",
    },
  },
});

export default function Sfab({ id, icon, size, onClick, count, color }) {
  return (
    <motion.div whileTap={{ scale: 0.9 }} className={styles.fabContainer}>
      <ThemeProvider theme={fabTheme}>
        <Fab onClick={()=>{
          onClick(id)
        }} size={size} color={color}>
          {icon == "menu" && <MenuSharpIcon />}
          {icon == "A" && <InfoIcon />}
          {icon == "E" && <WorkIcon />}
          {icon == "K" && <LightbulbIcon />}
          {icon == "L" && <PublicIcon />}
        </Fab>
      </ThemeProvider>
    </motion.div>
  );
}
