import styled from "styled-components";
import { motion } from "framer-motion";
import { useState } from "react";
import { useContext } from "react";

import bkground from "./backG.jpg";
import { AnimationContext } from "../Store/PageAnimation-Context";
import { device } from "../Util/DeviceBP";

const Background = styled(motion.div)`
  display: block;
  position: absolute;

  /* width: 120vw;
  height: 120vh; */
  background-color: #200b02;
  background-image: url(${bkground});
  background-attachment: fixed;
  background-position: 50% 50%;
  background-size: cover;
  overflow: hidden;
  z-index: -1;

  @media screen and (${device.allMobiles}) {
    display: none;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
  }

  @media screen and (${device.allLaptops}) {
    display: block;
      /* top: -20px;
  left: -20px;
  right: 0px;
    width: 110vw;
    height: 110vh; */
    /* overflow: hidden; */
  }
`;

export default function SiteBackground() {
  const { x, y } = useContext(AnimationContext);

  return (
    <div>
      <Background
        id="Background_Div"
        animate={{
          x: x,
          y: y,
        }}
        transition={{ ease: "easeIn", stiffness: 1, bounce: 1 }}
      ></Background>
    </div>
  );
}
