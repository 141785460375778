import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Container } from "./StyledComponents/Container";
import { device } from "../Util/DeviceBP";
// import Link from "react-router-dom"

import reactLogo from "../Content/reactjs.png";
import nodeLogo from "../Content/nodeJS.png";
import pythonLogo from "../Content/python.png";

const Knowledge_div = styled(motion.div)`
  overflow: hidden;
  height: auto;
  max-width: 100%;
  
  @media screen and (${device.allMobiles}) {
    height: auto;
    max-width: 99%;
  }
`;

const Certificates_div = styled(motion.div)`
  position: relative;
  display: flex;
  margin-bottom: 30px;

  justify-content: left;
  gap: 1rem;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: scroll;
  overflow-y: hidden;
  max-width: 100%;
  height: 135px;



  @media screen and (${device.allMobiles}) {
    width: 100%;
  }
`;
const Certificate_div = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  border: 1px solid #00a2ff25;
  border-radius: 12px;
  width: 25%;
  height: fit-content;
  padding-bottom: 5px;
  background-color: #00a2ff25;

  img {
    width: 70px;
    height: fit-content;
    margin: 0;
    margin-top :2px;
    text-align: left;
  }
  p {
    margin: 0;
    text-align: center;
    font-size: 12px;
    padding-right: 0;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  a {
    
    text-decoration: none;
    text-align: left;
    cursor: pointer;
  }

  @media screen and (${device.allMobiles}) {
    width: 100vw;
  }
`;

const CertText_div = styled.div`
  margin-left: 10px;

  a {
    color: #00cdfb;
    text-align: left;
    margin: 0;
    justify-content: left;
  }

  @media screen and (${device.allMobiles}) {
  }
`;
const Link_p = styled.p`
  color: #00cdfb;
`;

const Content_div = styled.div`
  width: 100px;
`;

const Tech_div = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: center;
  gap: 1rem;
  img {
    border-radius: 100%;
    width: 50px;
    height:50px;
  }
`;
const Crazy_div = styled.div`
overflow-y: scroll;
height: 30vh;
padding-bottom: 200px;


`

const Skill_div = styled.div`

 
  border-radius: 12px;

  height: fit-content;
  padding-bottom: 5px;
  background-color: none;

  img {
    width: 50px;
    height: fit-content;
    margin: 0;
   
    text-align: left;
  }

  p {
    margin: 0;
    text-align: left;
    font-size: 12px;
    padding-right: 0;
    width: auto;

  }

  a {
    
    text-decoration: none;
    text-align: left;
    cursor: pointer;
  }

  @media screen and (${device.allMobiles}) {
   
  }
`
const ContentSkills_div = styled.div`

  width: auto;
  display: flex;
 
`;

const Skills_div = styled.div`

 display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: left;
  align-items: left;
`


export default function Knowledge({ section }) {
  const proCerts = section.certificates[0].list;
  const appliedSkils = section.appliedSkills[0].list;
  const devStack = section.devStack;
  
  return (
    <Container>
      <h1>{section.title}</h1>
      <p>{section.description}</p>
      <Knowledge_div>
        {/* <h2>{section.certificates[0].title}</h2> */}
        <Certificates_div>
          {proCerts.map((certificate) => {
            return (
              <Certificate_div key={certificate.id}>
                <Content_div>
                  <img src={certificate.icon} alt={certificate.title}></img>
                  <CertText_div>
                    
                    {/* {certificate.current ? (
                      <p style={{ color: "#29cf9d" }}>Current</p>
                    ) : (
                      <p style={{ color: "#c44716" }}>Expired</p>
                    )} */}

                    <a href={certificate.verifyUlr}>
                      <p style={{ color: "#20a0d6" }}>Verify</p>
                    </a>
                  </CertText_div>
                </Content_div>
              </Certificate_div>
            );
          })}
        </Certificates_div>
        <h2>{section.appliedSkills[0].title}</h2>
        <Skills_div>
          {appliedSkils.map((skill) => {
            return (
              <Skill_div key={skill.id}>
                <ContentSkills_div>
                  <img src={skill.icon} alt={skill.title}></img>
                  <CertText_div>
                    <p>{skill.title}</p>
                    {/* {skill.current ? (
                      <p style={{ color: "#29cf9d" }}>Current</p>
                    ) : (
                      <p style={{ color: "#c44716" }}>Expired</p>
                    )} */}

                    <a href={skill.verifyUlr}>
                      <p style={{ color: "#20a0d6" }}>Verify</p>
                    </a>
                  </CertText_div>
                </ContentSkills_div>
              </Skill_div>
            );
          })}
        </Skills_div>
        <Crazy_div>
        <h2> Dev Stack</h2>
        <Tech_div>
          <img src={reactLogo} alt="TechStack" />
          <img src={nodeLogo} alt="TechStack" />
          <img src={pythonLogo} alt="TechStack" />
        </Tech_div>
        <div>
          <h2>In Progress</h2>
          <p> AI-102 | Azure AI Engineer Associate</p>
          <p> AZ-204 | Azure Developer Associate</p>
          
          <h2>Formal Education</h2>
          <p> Bachelor of Science in Systems Engineering | Universidad Fidelitas | 50% completed </p>
          
        </div>
        </Crazy_div>
      </Knowledge_div>
    </Container>
  );
}
