import styled from "styled-components";
import { motion, AnimatePresence, delay } from "framer-motion";

import Music from "@mui/icons-material/MusicNote";
import Cycling from "@mui/icons-material/DirectionsBike";
import Code from "@mui/icons-material/Code";
import Family from "@mui/icons-material/Diversity1";
import { useState } from "react";
import { device } from "../Util/DeviceBP";

import {Container} from './StyledComponents/Container'

const AboutContainer = styled(motion.div)`
  padding-top: 0;
  margin: auto;
  border-radius: 15px;
  width: 90%;

  h1 {
    color: #00cdfb;
  }

  p {
    margin: auto;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 20px;
    /* width: 80%; */
    text-align: center;
    font-size: 15px;
  }
`;

const AboutP = styled.p`
  @media screen and (${device.allLaptops}) {
    padding-top: 2px;
    width: 90%;
    font-size: 16px;
  }

  @media screen and (${device.allMobiles}) {
    padding-top: 2px;
    width: 98%;
    font-size: 12px;
  text-align:justify;
  }
`;

const AboutH1 = styled.h1`
  @media screen and (${device.allTablets}), (${device.allLaptops}) {
    width: 90%;
    padding-top: 2px;
    margin: auto;
    margin-top: 10px;
    font-size: 24px;
  }

  @media screen and (${device.allMobiles}) {
    width: 100%;
    padding-top: 2px;
    margin-top: 10px;
    font-size: 20px;
  }
`;

const InterestContainer = styled.div`
  margin-top: 2vh;
  display: flex;
  justify-content: center;
  gap: 1rem;
`;

const Interest = styled(motion.button)`
  background: none;
  border: none;
  padding-top: 8px;
  box-shadow: none;
  border-top: 1px solid #01c7f32c;
  border-radius: 10px;
  min-width: 8vw;
  min-height: fit-content;
  cursor: pointer;

  &:hover {
    background-color: #2626260e;
    -webkit-box-shadow: 0px 20px 30px -25px rgba(0, 205, 251, 0.89);
    -moz-box-shadow: 0px 20px 30px -25px rgba(0, 205, 251, 0.89);
    box-shadow: 0px 20px 30px -25px rgba(0, 205, 251, 0.89);
  }

  @media screen and (${device.allMobiles}) {
    padding-top: 10px;
    min-width: 20%;
    min-height: fit-content;
    width: 8vw;
  }
`;

const InterestDescription = styled(motion.div)`
  margin: auto;
  padding: 20px 5px 20px 5px;
  border-radius: 15px;
  border-bottom: 1px solid #00cdfb;
  margin-top: 15px;
  background: rgb(0, 205, 251);
  background: linear-gradient(
    180deg,
    rgba(0, 205, 251, 0.039653361344537785) 0%,
    rgba(0, 205, 251, 0.20772058823529416) 95%,
    rgba(0, 212, 255, 0.12088585434173671) 100%
  );
  color: #fff;
  width: 85%;
  height: fit-content;

  @media screen and (${device.allMobiles}) {
    margin-top: 15px;
    padding: 5px;
    width: 100%;
    font-size: 14px;
  }
`;

const InterestIcon = styled(motion.div)`
  color: #00cdfb;
`;
export default function About({ section }) {
  const [showMore, setShowMore] = useState(false);

  const [activeInterest, setActiveInterest] = useState("");

  const handleClick = (event) => {
    setShowMore(!showMore);

    setActiveInterest(
      section.interests.filter((interest) => interest.icon === event.target.id)
    );
  };

  return (
    <AnimatePresence>
      <Container
        initial={{ x: -300, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 300, opacity: 0 }}
        transition={{ delay: 0.5 }}
      >
        <AboutH1>{section.title}</AboutH1>
        <AboutP>{section.about}</AboutP>
        <AboutH1> What are my interests?</AboutH1>
        <InterestContainer>
          {section.interests.map((interest) => (
            <Interest
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              key={interest.icon}
              onClick={() => {
                setShowMore(true);
              }}
              onMouseEnter={handleClick}
              onMouseLeave={() => {
                setShowMore(false);
              }}
              id={interest.icon}
            >
              {interest.icon === "music" && (
                <InterestIcon
                  whileHover={{ scale: 1.5 }}
                  whileTap={{ scale: 0.9 }}
                  id={interest.icon}
                >
                  <Music id={interest.icon} />
                </InterestIcon>
              )}
              {interest.icon === "cycling" && (
                <InterestIcon
                  whileHover={{ scale: 1.5 }}
                  whileTap={{ scale: 0.9 }}
                  id={interest.icon}
                >
                  <Cycling id={interest.icon} />
                </InterestIcon>
              )}
              {interest.icon === "code" && (
                <InterestIcon
                  whileHover={{ scale: 1.5 }}
                  whileTap={{ scale: 0.9 }}
                  id={interest.icon}
                >
                  <Code id={interest.icon} />
                </InterestIcon>
              )}
              {interest.icon === "family" && (
                <InterestIcon
                  whileHover={{ scale: 1.5 }}
                  whileTap={{ scale: 0.9 }}
                  id={interest.icon}
                >
                  <Family id={interest.icon} />
                </InterestIcon>
              )}

              <p id={interest.icon}>{interest.title}</p>
            </Interest>
          ))}
        </InterestContainer>
        {showMore && activeInterest[0] && (
          <AnimatePresence>
            <InterestDescription
              initial={{ y: 100, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -100, opacity: 0 }}
            >
              {activeInterest[0].description}
            </InterestDescription>
          </AnimatePresence>
        )}
      </Container>
    </AnimatePresence>
  );
}
