import { useContext, useState } from "react";
import {
  ReactiveContext,
  CurrentScreenWidth,
} from ".././Store/PageAnimation-Context";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Hidden } from "@mui/material";
import SectionButtons from "../SharedUI/SectionButtons";
import About from "./About";
import Languages from "./Languages";
import Experience from "./Experience";
// import ProfileBox from "../SectionOne/ProfileBox";
import ProfileBox from "./Profile";

import { device } from "../Util/DeviceBP";

import myPict from "../SectionOne/cfm01.jpg";
import HiddenArea from "../SharedUI/HiddenArea";
import Knowledge from "./Knowledge";

const ContentAreaStyled = styled(motion.div)`
  display: block;
  width: 100%;
  max-width: 100%;

  @media screen and (${device.allLaptops}), (${device.allTablets}) {
    display: flex;
  }
`;

const SectionContent = styled(motion.div)`

  overflow: hidden;
  display: block;

  justify-content: center;
  /* align-items: center; */
  margin: auto;
  width: 70%;
  height: 100%;
  text-align: center;

  /* h1 {
    color: #00cdfb;
    width: 50%;
    margin: auto;
  } */

  @media screen and (${device.allMobiles}) {
    width: 100%;
    height: 75vh;
  }
  @media screen and (${device.allLaptops}), (${device.allTablets}) {
    display: none;
  }
`;

const MYINFO = {
  name: "Cristobal Fallas",
  img: myPict,
  title: "Senior IT Professional",
  about:
    "As a versatile IT professional with extensive experience across several technical subjects, I excel at delivering innovative solutions and driving efficiency. Known for my strong communication skills and collaborative approach, I effectively bridge technical concepts with clear insights. Passionate about sharing knowledge and mentoring, I foster a culture of continuous learning while staying current with the latest advancements to adapt to the evolving IT landscape and contribute to team success.",
};

export default function ContentArea({ sections }) {
  const [displaySection, setDisplaySection] = useState("about");

  const { interactive, deviceWidth, handleInteractive } =
    useContext(ReactiveContext);

  const activeSection = sections.filter(
    (section) => section.id === displaySection
  );

  function handleButtonClick(id) {
    setDisplaySection(id);
  }

  return (
    <ContentAreaStyled>
      <ProfileBox
        profile={MYINFO}
        onClickHandler={handleButtonClick}
      ></ProfileBox>

      <SectionContent
        animate={
          interactive && deviceWidth > 767
            ? {
                display: "block",
              }
            : null
        }
      >
        <SectionButtons
          sections={sections}
          onClickHandler={handleButtonClick}
          active={displaySection}
        ></SectionButtons>
        {displaySection === "select" && (
          <p>Click the buttons above to learn more about me!</p>
        )}
        {displaySection === "about" && <About section={activeSection[0]} />}
        {displaySection === "languages" && (
          <Languages section={activeSection[0]} />
        )}
        {displaySection === "experience" && (
          <Experience section={activeSection[0]} />
        )}
        {displaySection === "knowledge" && (
          <Knowledge section={activeSection[0]} />
        )}
      </SectionContent>
    </ContentAreaStyled>
  );
}
