import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";

import { useState } from "react";
import { Container } from "./StyledComponents/Container";

import { device } from "../Util/DeviceBP";

import Button from "../SharedUI/Button";

const ExperienceDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  width: 100%;

  @media screen and (${device.allMobiles}) {
    flex-direction: column;
  }
`;
const ExperienceMenuDiv = styled.div`
  min-width: 20%;
  display: flex;
  flex-direction: column;

  @media screen and (${device.allMobiles}) {
    flex-direction: row;
    min-width: 100%;
    justify-content: center;
  }
`;
const RoleDiv = styled(motion.div)`
  border: 1px solid #00cdfb;
  padding-top: 10px;
  width: 98%;
  min-width: 98%;
  margin: auto;
  margin-bottom: 0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom: none;
  background-color: none;
  height: 200px;
  overflow-y: scroll;

  p {
    margin: 0;
    width: 90%;
    margin: auto;
    text-align: left;
  }

  h3 {
    color: #00cdfb;
    width: 90%;
    margin: auto;
    margin-bottom: 15px;
    text-align: left;
  }

  span {
    font-weight: bold;
  }

  li {
    list-style-type: none;
  }

  @media screen and (${device.allMobiles}) {
  }
`;

const CompanyDiv = styled(motion.div)`
  display: flex;
  width: 90%;
  margin-left: 22.34px;
  justify-content: left;

  align-items: center;
  width: fit-content;
  p {
    margin: 0 auto;
  }

  a {
    padding-top: 5px;
    cursor: pointer;
  }
  a:visited {
    color: #00cdfb;
  }
`;

const ActionButtonsDiv = styled(motion.div)`
  width: 98%;
  margin: auto;
  border: 1px solid #00cdfb;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  background-color: #00cdfb3d;

  p {
    margin: 10px auto;
  }
`;

const RoleInfoDiv = styled(motion.div)``;

const RoleCardDiv = styled.div`
  width: 80%;
  @media screen and (${device.allMobiles}) {
    width: 100%;
  }
`;

const SkillsDiv = styled(motion.div)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  h4 {
    color: white;
    margin-top: 15px;
  }

  h4,
  li {
    color: white;
  }
  p {
    color: white;
  }

  @media screen and (${device.allMobiles}) {
    flex-direction: column;
  }
`;

const TechUL = styled.ul`
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;

  img {
    width: 45px;
    height: 45px;
    cursor: pointer;
  }
  p {
    font-size: 11px;
    color: #fffa;
    opacity: 0;
    display: none;
    width: fit-content;
    margin: 0;
    margin-top: -5px;
  }
`;

const SkillsUL = styled.ul`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;

  li {
    border: 1px solid #ffffff98;
    border-radius: 8px;
    padding: 0 5px;
    font-size: 12px;
  }
`;

const TechDiv = styled.div`
  width: 65%;

  @media screen and (${device.allMobiles}) {
    width: 100%;
  }
`;

const SkillDiv = styled.div`
  width: 45%;

  @media screen and (${device.allMobiles}) {
    width: 100%;
  }
`;
export default function Experience({ section }) {
  const jobHistory = section.history;
  const currentPosition = jobHistory.filter((job) => {
    return job.current;
  });

  const previousPositions = jobHistory.filter((job) => {
    return !job.current;
  });

  const [activeRole, setActiveRole] = useState(0);
  const [expandRole, setExpandRole] = useState(false);
  const [hover, setHover] = useState("");

  const hoverHandler = (event) => {
    setHover(event.target.id);
  };

  const expandHandler = () => {
    setExpandRole(!expandRole);
  };

  const viewRole = (id) => {
    setActiveRole(id - 1);
  };

  return (
    <AnimatePresence>
      <Container
        initial={{ x: -300, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 300, opacity: 0 }}
      >
        <motion.h1
        //  initial={{ textAlign: "center", opacity: 100 }}
        //  animate={expandRole ? { textAlign:"left" } : null}
        >
          {section.title}
        </motion.h1>
        <motion.p>{section.description}</motion.p>
        <ExperienceDiv>
          <ExperienceMenuDiv>
            {jobHistory.map((job) => {
              return (
                <Button
                  key={job.id}
                  active={activeRole + 1}
                  clickHandler={viewRole}
                  id={job.id}
                  label={`${job.current ? "Current Role" : "Previous"}`}
                ></Button>
              );
            })}
          </ExperienceMenuDiv>
          <RoleCardDiv>
            <RoleDiv
              initial={{ y: 0, height: "190px", overflowY: "hidden" }}
              animate={
                expandRole
                  ? { y: 0, height: "320px", overflowY: "scroll" }
                  : null
              }
              exit={{ x: 300, height: "190px", overflowY: "hidden" }}
            >
              <h3>{jobHistory[activeRole].title}</h3>

              <p>
                <span>Company:</span> {jobHistory[activeRole].company}
              </p>

              <p>
                <span>Dates: </span>

                {jobHistory[activeRole].dates}
              </p>

              <p>
                <span>Main Function: </span>
                {jobHistory[activeRole].description}
              </p>

              <RoleInfoDiv
                initial={{ display: "none", opacity: 0 }}
                animate={expandRole ? { display: "block", opacity: 1 } : null}
                exit={{ display: "none", opacity: 0 }}
              >
                <div>
                  <p>
                    <span>Responsibilities:</span>
                  </p>
                  <ul>
                    {jobHistory[activeRole].functions.map((fun) => {
                      return (
                        <li key={fun}>
                          <p>* {fun}</p>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <SkillsDiv>
                  <TechDiv>
                    {" "}
                    <h4>Technologies</h4>
                    <TechUL>
                      {jobHistory[activeRole].techs.map((tech) => {
                        return (
                          <motion.li
                            id={tech.title}
                            onHoverStart={hoverHandler}
                            onHoverEnd={() => {
                              setHover("");
                            }}
                            whileHover={{ scale: 1.1 }}
                            key={tech.title}
                          >
                            {" "}
                            <motion.p
                              animate={
                                hover === tech.title
                                  ? { width: "fit-content", opacity: 1, display: "block"}
                                  : { width: "fit-content", opacity: 0, display: "none" }
                              }
                            >
                              {tech.title}
                            </motion.p>
                            <img src={tech.icon} alt={tech.title} />
                          </motion.li>
                        );
                      })}
                    </TechUL>
                  </TechDiv>
                  <SkillDiv>
                    <h4>Skills</h4>
                    <SkillsUL>
                      {jobHistory[activeRole].skills.map((skill) => {
                        return <li key={skill}>{skill}</li>;
                      })}
                    </SkillsUL>
                  </SkillDiv>
                </SkillsDiv>
              </RoleInfoDiv>
            </RoleDiv>
            <ActionButtonsDiv
              onClick={expandHandler}
              animate={expandRole ? { backgroundColor: "#00cdfb13" } : null}
            >
              <p>{expandRole ? "Hide details" : "View more about this role"}</p>
            </ActionButtonsDiv>
          </RoleCardDiv>
        </ExperienceDiv>
      </Container>
    </AnimatePresence>
  );
}
