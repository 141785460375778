import Button from "./Button";
import styled from "styled-components";
import {motion} from "framer-motion"

import { device } from "../Util/DeviceBP";

const FlexDiv = styled(motion.div)`

  display: block;
  margin: auto;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  margin-top: 2vh;
  margin-bottom: 2vh;

  @media screen and (${device.allMobiles}) {
    display: none;
    position:fixed;
    flex-direction: column;
    margin: 0;
    width: 30vw;
  }

  @media screen and (${device.allLaptops}) , (${device.allTablets}){
    
  }
`;

export default function SectionButtons({ sections, onClickHandler, active }) {
  return (
    <FlexDiv>
      {sections.map((section) => {
        return (
          <Button
            key={section.id}
            active={active}
            label={section.title}
            id={section.id}
            clickHandler={onClickHandler}
          ></Button>
        );
      })}
    </FlexDiv>
  );
}
