import styled from "styled-components";

import { device } from "../Util/DeviceBP";

export const Page = styled.div`

  background-color: #48859b;
  background-image: linear-gradient(
    to right top,
    #000000,
    #1f1a1d,
    #342d39,
    #424558,
    #495f77,
    #495f77,
    #495f77,
    #495f77,
    #424558,
    #342d39,
    #1f1a1d,
    #000000
  );
  margin: auto;
  padding: 1vh;
  position: static;
  display: block;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  overflow:hidden;
`;

export const PageContent = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  
  max-width: 100%;
  max-height: 100%;
  min-height: fit-content;
  margin: 2vh auto;
  padding: 0;
  background-color: #444;
  -webkit-box-shadow: 0 0 100px -5px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 0 100px -5px rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 100px -5px rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  /* overflow:hidden; */

  @media screen and (${device.allMobiles}) {
    width: 95vw;
    margin: auto;
  
    height: 95vh;
    min-height: 90vh;
    max-width: 90vh;
  }

  @media screen and (${device.allTablets}), (${device.allLaptops}) {
    width: 90vw;
    height: 95vh;
    min-height: 90vh;
    max-width: 90vw;
  }
`;
