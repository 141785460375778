import { useState, useEffect } from "react";

import myPict from "./SectionOne/cfm01.jpg";
import cr from "./Content/cr.png";
import br from "./Content/br.png";
import usa from "./Content/usa.png";
import intune from "./Content/intune.png";
import graph from "./Content/graph.png";
import azure from "./Content/azure.png";
import pshell from "./Content/powershell.png";
import entra from "./Content/entra.png";
import storage from "./Content/storage.png";
import sharepoint from "./Content/shpoint.webp";
import network from "./Content/network.png";
import officead from "./Content/office.png";

import pautomate from "./Content/pauto.png";
import python from "./Content/python.webp";
import datafact from "./Content/adfact.png";
import vmware from "./Content/vmware.png";
import exchange from "./Content/exchange.png";
import winserver from "./Content/windows.png";
import activead from "./Content/winad.png";
import officetools from "./Content/office365.png";

import sc300 from "./Content/SC-300.svg";
import az900 from "./Content/AZ-900.svg";
import az104 from "./Content/AZ-104.png";
import ccna1 from "./Content/ccna1.png";

import {
  ReactiveContext,
  AnimationContext,
  CurrentScreenWidth,
} from "./Store/PageAnimation-Context";

import SiteGlobalStyle from "./SharedUI/SiteGlobalStyle";
import SiteBackground from "./SharedUI/SiteBackground";
import { Page, PageContent } from "./SharedUI/Page";

import CoverPage from "./Cover/CoverPage";
import ContentArea from "./Content/ContentArea";
import { Details } from "@mui/icons-material";

const MYINFO = {
  name: "Cristobal Fallas",
  img: myPict,
  title: "Senior IT Professional",
  about:
    "I am an IT Professional with experience in several technology subjects. I'm always willing to learn and expand my knowledge to be a top performer employee. Great team player, with strong communication skills and ease to share knowledge with peers and others to promote a nice work environment.",
};

const CVPARTS = [
  {
    title: "Professional Profile",
    id: "about",
    about:
      "I am a versatile IT professional with over eight years of experience in a wide range of roles, driven by a passion for learning and adopting new technologies. My expertise spans across systems administration, networking, scripting, automation, virtualization, Azure cloud services, DevOps, and web application development, allowing me to develop a strong skill set and a deep understanding of multiple IT domains.",
    interests: [
      {
        title: "Family",
        icon: "family",
        description:
          "My family is always first! I love spending time with my wife, my daughter and our three lovely dogs and building memorable moments that we will always love.",
      },
      {
        title: "Music",
        icon: "music",
        description:
          "I enjoy music in all its forms, so I listen whenever I can, regardless of the genre or style. I also play a bit of bass and guitar,  so you can count on me for music projects!",
      },
      {
        title: "Cycling",
        icon: "cycling",
        description:
          "Going out for a ride throughout the mountain trials of Costa Rica is one of my hobbies. You get to know and sight see great places and landscapes in just two wheels.",
      },
      {
        title: "Code",
        icon: "code",
        description:
          "Passionate about coding, with a strong interest in developing efficient and scalable solutions. Continuously exploring new technologies and programming languages.",
      },
    ],
  },
  {
    title: "Experience",
    id: "experience",
    description:
      "With over 8 years of experience in the technology space, I have performed in multiple roles. Please expand the below for additional details.",
    history: [
      {
        id: 1,
        title: "Microsoft Entra ID SME",
        company: "Persistent Systems",
        companyLinkedIn: "https://www.linkedin.com/company/persistent-systems/",
        current: true,
        dates: "May 2023 - November 2024 (1.5 years)",
        description:
          "Guide and support Customers to deploy Entra ID P1 | P2 services and features. (Contractor for Microsoft Corporation)",
        functions: [
          "Lead technical workshops with customers.",
          "Guide pilot deployment process end to end and ensure readiness for scaled deployments.",
          "Deliver best practices and perform troubleshooting to facilitate Entra ID deployment project success.",
        ],
        skills: [
          "Problem Solving",
          "Project Management",
          "Active Listening",
          "Communicantion",
          "Results-Oriented",
        ],
        techs: [
          { title: "Entra ID", icon: entra },
          { title: "Azure", icon: azure },
          { title: "PowerShell", icon: pshell },
          { title: "MSGraph", icon: graph },
          { title: "Intune", icon: intune },
        ],
      },
      {
        id: 2,
        title: "IT Support Engineer",
        company: "Atlas",
        companyLinkedIn: "https://www.linkedin.com/company/atlashxm/",
        current: false,
        dates: "March 2021 - May 2023 (2.3 years)",
        description: "Escalation Engineer and Automation Delivery",
        functions: [
          "Systems and applications administrator (Active Directory, Entra ID, Azure, Office 365)",
          "Collaborate in automation projects and support after implementation",
          "Task automation via Python, PowerShell, PowerAutomate and ETL flows with Azure Data Factory ",
        ],
        skills: [
          "Problem Solving",
          "Creativity",
          "Planning",
          "Decision-making",
          "Self-motiviation",
          "Critical thinking",
        ],
        techs: [
          { title: "Entra ID", icon: entra },

          { title: "Azure", icon: azure },
          { title: "PowerShell", icon: pshell },
          { title: "MSGraph", icon: graph },
          { title: "Office 365", icon: officead },
          { title: "Power Automate", icon: pautomate },
          { title: "Python", icon: python },
          { title: "Azure Data Factory", icon: datafact },
          { title: "SharePoint", icon: sharepoint },
        ],
      },
      {
        id: 3,
        title: "Technical Consultant",
        company: "GSK",
        companyLinkedIn: "https://www.linkedin.com/company/glaxo/",
        current: false,
        dates: "July 2017 – January 2021 (3.5 years)",
        description:
          "Manage end to end provisioning process for servers, VMs, storage and other infrastructure requests.",
        functions: [
          "Manage and support IT infrastructure provisioning and decommisioning.",
          "Join company project teams and support new site / tech refresh implementations.  ",
          "Participate and lead meetings with internal customers and design team to provide consultancy ",
        ],
        skills: [
          "Problem Solving",
          "Willingness to learn",
          "Project Management",
          "Adaptability",
        ],
        techs: [
          { title: "Vmware", icon: vmware },
          { title: "PowerShell", icon: pshell },

          { title: "Windows Server", icon: winserver },
          { title: "Storage", icon: storage },
          { title: "Networking", icon: network },
        ],
      },
      {
        id: 4,
        title: "Technical Author",
        company: "GSK",
        companyLinkedIn: "https://www.linkedin.com/company/glaxo/",
        current: false,
        dates: "April 2016 – Jun 2017 (1.2 years)",
        description:
          "Create, maintain, and support technical documentation for IT Hosting Solutions",
        functions: [
          "Support technical teams to maintain documentation.",
          "Manage team’s SharePoint and document libraries.",
          "In charge of organizing, leading, and facilitating by-weekly team meetings.",
        ],
        skills: ["Assertive Communication", "Project Management"],
        techs: [
          { title: "Office Tools", icon: officetools },
          { title: "SharePoint", icon: sharepoint },
        ],
      },
      {
        id: 5,
        title: "Technical Writer, Trainer",
        company: "Equifax",
        companyLinkedIn: "https://www.linkedin.com/company/equifax/",
        current: false,
        dates: "August 2014 – April 2016 (1.7 years)",
        description:
          "Document processes, develop and deliver training sessions for the different operational areas on the shared service center",
        functions: [
          "Process documentation, manuals and SOP creation",
          "Training delivery for new hires",
          "Participate in process improvement initiatives",
        ],
        skills: [
          "Assertive Communication",
          "Project Management",
          "Communication Skills",
          "Training Delivery",
          "eLeaning & LMS",
        ],
        techs: [{ title: "Office Tools", icon: officetools }],
      },
    ],
  },
  {
    title: "Knowledge",
    id: "knowledge",
    description:
      "A brief summary of the different technology subjects and topics I have learned and worked with.",
    certificates: [
      {
        title: "Professional Certificates",
        description: "Description",
        list: [
          {
            icon: sc300,
            current: true,
            title: "Identity and Access Administrator Associate",
            id: "1576B0143A6B7AAC",
            verifyUlr:
              "https://learn.microsoft.com/en-us/users/cristobalfallascr/credentials/1576b0143a6b7aac?ref=https%3A%2F%2Fwww.linkedin.com%2F",
            skills: [
              "Implement and manage user identities",
              "Implement authentication and access management",
              "Plan and implement workload identities",
              "Plan and implement identity governance",
            ],
          },
          {
            icon: az900,
            current: true,
            title: "Azure Fundamentals",
            id: "93A0873F7CDE4A96",
            verifyUlr:
              "https://learn.microsoft.com/api/credentials/share/en-us/cristobalfallascr/93A0873F7CDE4A96?sharingId=4E7EFB8306381219",
            skills: [
              "Describe cloud concepts",
              "Describe Azure architecture and services",
              "Describe Azure management and governance",
            ],
          },
          {
            icon: az104,
            current: false,
            title: "Azure Administrator Associate",
            id: "az104",
            verifyUlr:
              "https://www.credly.com/badges/d25a2cc4-7855-4662-9f46-60c11c2900c4/linked_in_profile",
            skills: [
              "Describe cloud concepts",
              "Describe Azure architecture and services",
              "Describe Azure management and governance",
            ],
          },
          {
            icon: ccna1,
            current: true,
            title: "CCNA: Introduction to Networks",
            id: "ccna1",
            verifyUlr:
              "https://www.credly.com/badges/c18b7b08-fa1e-4a38-93ed-764b49b444b6?source=linked_in_profile",
            skills: [
              "Ethernet, IP Connectivity, IP Services",
              "IP Subnetting, IPv4 and IPv6 Addressing",
              "Nework and Security Fundamentals",
              "Switching",
            ],
          },
        ],
      },
    ],

    appliedSkills: [
      {
        title: "Applied Skills Credentials",
        description: "Description",
        list: [
          {
            icon: "https://learn.microsoft.com/en-us/media/profile/zero-state-applied-skills.svg?branch=main",
            current: true,
            title:
              "Build a natural language processing solution with Azure AI Language",
            id: "26697FD410085E0C",
            verifyUlr:
              "https://learn.microsoft.com/api/credentials/share/en-us/cristobalfallascr/26697FD410085E0C?sharingId=4E7EFB8306381219",
            skills: [],
          },
          {
            icon: "https://learn.microsoft.com/en-us/media/profile/zero-state-applied-skills.svg?branch=main",
            current: true,
            title:
              "Create an intelligent document processing solution with Azure AI Document Intelligence",
            id: "5CE360B6982E135C",
            verifyUlr:
              "https://learn.microsoft.com/api/credentials/share/en-us/cristobalfallascr/5CE360B6982E135C?sharingId=4E7EFB8306381219",
            skills: [],
          },
        ],
      },
    ],
    devStack: [
      {
        title: "NodeJs",
      },
      {
        title: "ReactJs",
      },
      {
        title: "Python",
      },
    ],
  },

  {
    title: "Languages",
    id: "languages",
    description:
      "I'm able to communicate in the languanges listed below. Hover over or click on each element to view additional information.",
    list: [
      {
        title: "Spanish",
        native: true,
        level: "Native Speaker",
        img: cr,
        description:
          "I was born in Costa Rica, a Latin American country where the main spoken language is Spanish.",
      },
      {
        title: "English",
        native: false,
        level: "Advanced Level",
        img: usa,
        description:
          "My journey with the English language began 18 ago when I started studying English Translation. Since then, I've been working with multinational companies which allows to expand my knowledge and vocabulary.",
      },
      {
        title: "Portuguese",
        native: false,
        level: "Elementary Proficiency",
        img: br,
        description:
          "This skill is currently in progress. I've been enrolled in a Portuguese course since the begining of 2024.",
      },
    ],
  },
];

function App() {
  const [animateX, setAnimateX] = useState(0);
  const [animateY, setAnimateY] = useState(0);
  const [animateBkg, setAnimateBkg] = useState({ x: 0, y: 0 });
  const [startInterative, setStartInteractive] = useState(false);

  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleScreenResize = () => {
      setDeviceWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleScreenResize);

    return () => {
      window.removeEventListener("resize", handleScreenResize);
    };
  }, []);

  const captureMouse = (event) => {
    if (event.movementX > 0) {
      setAnimateX(-20);
    } else {
      setAnimateX(20);
    }

    if (event.movementY > 0) {
      setAnimateY(-20);
    } else {
      setAnimateY(20);
    }

    setAnimateBkg({
      x: animateX,
      y: animateY,
    });
  };

  const handleInteractive = () => {
    setStartInteractive(!startInterative);
  };

  return (
    <ReactiveContext.Provider
      value={{
        deviceWidth: deviceWidth,
        interactive: startInterative,
        handleInteractive: handleInteractive,
      }}
    >
      <AnimationContext.Provider value={animateBkg}>

      <SiteGlobalStyle />
      <SiteBackground />

      <Page>
        <PageContent>
          <ContentArea sections={CVPARTS}></ContentArea>
        </PageContent>
      </Page>

      </AnimationContext.Provider>
    </ReactiveContext.Provider>
  );
}

export default App;
